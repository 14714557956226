<template>
    <div class="modal">
      <div class="modal-content">
        <span class="close" @click="$emit('close')">&times;</span>
        <h2>{{ task ? "Редактировать задачу" : "Создать задачу" }}</h2>
  
        <input
          v-model="localTask.name"
          placeholder="Имя задачи"
        />
  
        <!-- Поле для выбора времени начала, преобразуем в Unix Timestamp -->
        <input
          v-model="localStartTime"
          type="datetime-local"
          placeholder="Время начала"
          @change="updateStartTime"
        />
  
        <!-- Поле для выбора времени завершения, преобразуем в Unix Timestamp -->
        <input
          v-model="localEndTime"
          type="datetime-local"
          placeholder="Время завершения"
          @change="updateEndTime"
        />
  
        <button @click="saveTask">Сохранить</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, watch } from "vue";
  
  export default {
    props: {
      task: Object,
    },
    setup(props, { emit }) {
      const localTask = ref({
        name: "",
        start_timestamp: null,
        end_timestamp: null,
      });
  
      const localStartTime = ref("");
      const localEndTime = ref("");
  
      // Если редактируется существующая задача, заполняем поля
      watch(
        () => props.task,
        (newTask) => {
          if (newTask) {
            localTask.value = { ...newTask };
            // Преобразуем Unix timestamp в формат datetime-local
            if (newTask.start_timestamp) {
              localStartTime.value = new Date(newTask.start_timestamp * 1000)
                .toISOString()
                .slice(0, 16);
            }
            if (newTask.end_timestamp) {
              localEndTime.value = new Date(newTask.end_timestamp * 1000)
                .toISOString()
                .slice(0, 16);
            }
          }
        },
        { immediate: true }
      );
  
      // Обновление времени начала, преобразование в Unix timestamp
      function updateStartTime() {
        const date = new Date(localStartTime.value);
        localTask.value.start_timestamp = Math.floor(date.getTime() / 1000); // Преобразуем в секунды
      }
  
      // Обновление времени завершения, преобразование в Unix timestamp
      function updateEndTime() {
        const date = new Date(localEndTime.value);
        localTask.value.end_timestamp = Math.floor(date.getTime() / 1000); // Преобразуем в секунды
      }
  
      // Функция для сохранения задачи
      function saveTask() {
        emit("save", localTask.value);
      }
  
      return {
        localTask,
        localStartTime,
        localEndTime,
        updateStartTime,
        updateEndTime,
        saveTask,
      };
    },
  };
  </script>
  
  <style scoped>
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    background-color: #333;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
  }
  .close {
    color: white;
    float: right;
    font-size: 28px;
    cursor: pointer;
  }
  </style>