import { createRouter, createWebHistory } from 'vue-router';
import DataList from '@/views/DataList.vue';
import SetAuth from '@/views/SetAuth.vue';

const routes = [
  { path: '/', component: DataList },
  { path: '/set-auth', component: SetAuth },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
